import { useMemo } from 'react';
import * as default_data from '../../config.js'
import { FormControl, MenuItem, Select, useTheme } from '@mui/material'

export const SelectModel = ({value, cur_user, onChange, paid}) => {
  const theme = useTheme();
  var allowed_models = default_data.default_allowed_models;

  const filteredOptions = [
    { key: 'auto', value: 'GPT Auto*', paid: true, superadmin_only: false },
    { key: 'gpt', value: 'GPT3.5*', paid: false, superadmin_only: false },
    { key: 'gpt35-16k', value: 'GPT3.5-16K*', paid: true, superadmin_only: false },
    { key: 'gpt4', value: 'GPT4*', paid: false, superadmin_only: false },
    { key: 'gpt-4o', value: 'GPT-4o*', paid: true, superadmin_only: false },
    { key: 'gpt-4o-mini', value: 'GPT-4o-mini*', paid: true, superadmin_only: false },
    { key: 'gpt-o1-mini', value: 'GPT-o1-mini*', paid: true, superadmin_only: false },
    { key: 'gpt-o1', value: 'GPT-o1*', paid: true, superadmin_only: false },
    { key: 'gpt4-32k', value: 'GPT4-32K*', paid: true, superadmin_only: false },
    { key: 'gpt4-vision', value: 'GPT4-V*', paid: true, superadmin_only: false },
    { key: 'gpt-gov', value: 'GPT3.5 Gov*', paid: false, superadmin_only: false },
    { key: 'gpt4-gov', value: 'GPT4 Gov*', paid: false, superadmin_only: false },
    { key: 'gpt-4o-gov', value: 'GPT4o Gov*', paid: true, superadmin_only: false },
    { key: 'dall-e-2', value: 'DALL-E (2)', paid: true, superadmin_only: false },
    { key: 'dall-e-3', value: 'DALL-E (3)*', paid: true, superadmin_only: false },
    { key: 'mpt-7b-chat', value: 'MPT-7B-Chat*', paid: true, superadmin_only: true },
    { key: 'dolly', value: 'Dolly*', paid: true, superadmin_only: true },
    { key: 'flan-t5', value: 'Flan T5-XL*', paid: true, superadmin_only: true },
    //{ key: 'llma2', value: 'LLMA-2', paid: true, superadmin_only: false },
    { key: 'llma3', value: 'LLMA-3', paid: true, superadmin_only: false },
    { key: 'claude2', value: 'Claude 2', paid: true, superadmin_only: false },
    { key: 'claude-3-opus', value: 'Claude 3 O.', paid: true, superadmin_only: false },
    { key: 'claude-3-sonnet', value: 'Claude 3 S.', paid: true, superadmin_only: false },
    { key: 'claude-35-sonnet', value: 'Claude 3.5 S.', paid: true, superadmin_only: false },
    { key: 'falcon', value: 'Falcon*', paid: true, superadmin_only: true },
    { key: 'aws-bedrock-titan', value: 'Titan*', paid: true, superadmin_only: true },
    { key: 'google-bison', value: 'Google Bison', paid: true, superadmin_only: false },
    { key: 'google-gemini-pro', value: 'Google Gemini Pro', paid: true, superadmin_only: false },
    { key: 'mistral-large', value: 'Mistral Large', paid: true, superadmin_only: false },
    { key: 'cohere', value: 'Cohere', paid: false, superadmin_only: false },
    { key: 'groq-70b', value: 'Groq-70B', paid: true, superadmin_only: false },
  ].filter((option) => allowed_models.includes(option.key));

  const options = useMemo(() => {
    return filteredOptions.map((option) => {
      if ((option.paid === true && paid !== true) || (option.superadmin_only === true && cur_user.type !== 'superadmin')) {
        return null;
      }
      return (
        <MenuItem key={option.key} value={option.key} sx={{fontSize: 13}}>
          {option.value}
        </MenuItem>
      );
    }).filter((option) => option !== null);
  }, [filteredOptions, paid, cur_user]);

  return (  
      <Select 
        value={value} 
        onChange={onChange} 
        defaultValue={'gpt'} 
        className="select-menu" 
        sx={{
          backgroundColor: theme.palette.dropdowns.backgroundColor,
        }}
        MenuProps={{
          style: {
            maxHeight: 500,          
          }
        }}
      >
        {options}
      </Select>
  );
};