export const models = [
  {
    model: "GPT Auto", 
    value: "GPT Auto*",
    key: "auto",
    contextSize: "Depend on model selected",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "Depend on model selected",
    comment: "New feature: Ask Sage will select automatically the best Azure OpenAI (non Gov) model based on the prompt size and whether or not you attached files/images etc. This is only for text generation.",
    paidText: undefined,
    paid: true,
    superadmin_only: false,
  },
  {
    model: "Azure OpenAI GPT-3.5",

    contextSize: "4,096 (10 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens = 10 tokens",
    comment: "Good model, inexpensive, and good ROI.",
    paidText: undefined,
    key: 'gpt',
    value: 'GPT3.5*',
    paid: false,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-3.5-16K",
    contextSize: "16,384 (40 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 6.66 tokens\n1 Ask Sage tokens (completion) = 5 tokens",
    comment: "Inexpensive and good ROI for large token window.",
    paidText: "Paid Users Only",
    key: 'gpt35-16k',
    value: 'GPT3.5-16K*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-4.0",
    contextSize: "8,192 (20 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 0.667 tokens\n1 Ask Sage tokens (completion) = 0.333 tokens",
    comment: "Most capable model today. GPT4 is about 5X more expensive than Ask Sage tokens and 50X more expensive than GPT3.5",
    paidText: undefined,
    key: 'gpt4',
    value: 'GPT4*',
    paid: false,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-4o",
    contextSize: "128,000 (300 pages)\nonly 4,096 responses",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 4 tokens\n1 Ask Sage tokens (completion) = 1.33 tokens",
    comment: "Latest OpenAI GPT 4o model. Capable of ingesting and analyzing images (JPG, PNG, GIF (20MB files max)). Only 4,096 token response max.",
    paidText: "Paid Users Only",
    key: 'gpt-4o',
    value: 'GPT-4o*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-4o-mini",
    contextSize: "128,000 (300 pages)\nonly 16,384 responses",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 120 tokens\n1 Ask Sage tokens (completion) = 30 tokens",
    comment: "Latest OpenAI GPT 4o model. Least expensive. Capable of ingesting and analyzing images (JPG, PNG, GIF (20MB files max)).  16,384 token response max.",
    paidText: "Paid Users Only",
    key: 'gpt-4o-mini',
    value: 'GPT-4o-mini*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-4.0-32K",
    contextSize: "32,768 (80 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 0.333 tokens\n1 Ask Sage tokens (completion) = 0.167 tokens",
    comment: "Quite expensive but largest context window and most performant model. GPT4-32K is about 9X more expensive than Ask Sage tokens and 90X more expensive than GPT3.5",
    paidText: "Paid Users Only",
    key: 'gpt4-32k',
    value: 'GPT4-32K*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-4.0-Vision",
    contextSize: "128,000 (300 pages)\nonly 4,096 responses",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 2 tokens\n1 Ask Sage tokens (completion) = 0.667 tokens",
    comment: "Capable of ingesting and analyzing images (JPG, PNG, GIF (20MB files max)). Only 4,096 token response max. Images will be converted into tokens and can be expensive.",
    paidText: "Paid Users Only",
    key: 'gpt4-vision',
    value: 'GPT4-V*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Whisper",
    contextSize: "Speech to text",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "1,000 Ask Sage tokens per file (25MB/chunks)",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: undefined,
  },
  {
    model: "TTS*",
    contextSize: "Text to speech",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "1 Ask Sage tokens = 0.36 tokens",
    comment: "Text to speech model",
    paidText: "Paid Users Only",
    paid: true
  },
  {
    model: "TTS-HD*",
    contextSize: "Text to speech",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "1 Ask Sage tokens = 0.18 tokens",
    comment: "Text to speech HD model",
    paidText: "Paid Users Only",
    paid: true
  },
  {
    model: "Azure Gov OpenAI GPT-3.5",
    contextSize: "16,384 (40 pages)\nonly 4,096 responses",
    sensitiveData: "Yes",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens = 7.5 tokens",
    comment: "U.S. Government. Inexpensive and good ROI",
    paidText: undefined,
    key: 'gpt-gov',
    value: 'GPT3.5 Gov*',
    paid: false,
    superadmin_only: false 
  },
  {
    model: "Azure Gov OpenAI GPT-4.0",
    contextSize: "128,000 (300 pages)\nonly 4,096 responses",
    sensitiveData: "Yes",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 0.5 tokens\n1 Ask Sage tokens (completion) = 0.25 tokens",
    comment: "U.S. Government. Most capable model today. GPT4 is about 5X more expensive than Ask Sage tokens and 50X more expensive than GPT3.5",
    paidText: undefined,
    key: 'gpt4-gov',
    value: 'GPT4 Gov*',
    paid: false,
    superadmin_only: false 
  },
  {
    model: "Azure Gov OpenAI GPT-4o",
    contextSize: "128,000 (300 pages)\nonly 4,096 responses",
    sensitiveData: "Yes",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 3 tokens\n1 Ask Sage tokens (completion) = 1 tokens",
    comment: "U.S. Government. Latest OpenAI GPT 4o model. More inexpensive than GPT4. Capable of ingesting and analyzing images (JPG, PNG, GIF (20MB files max)). Only 4,096 token response max.",
    paidText: "Paid Users Only",
    key: 'gpt-4o-gov',
    value: 'GPT4o Gov*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-o1",
    contextSize: "128,000 (300 pages)\n32,768 responses",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 1.21 tokens\n1 Ask Sage tokens (completion) = 0.30 tokens",
    comment: "Latest OpenAI GPT o1 model.",
    paidText: "Paid Users Only",
    key: 'gpt-o1',
    value: 'GPT-o1*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI GPT-o1-mini",
    contextSize: "128,000 (300 pages)\n65,536 responses",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 6 tokens\n1 Ask Sage tokens (completion) = 1.5 tokens",
    comment: "Latest OpenAI GPT o1 mini model.",
    paidText: "Paid Users Only",
    key: 'gpt-o1-mini',
    value: 'GPT-o1-mini*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "OpenAI DALL-E-2",
    contextSize: "1,024",
    sensitiveData: "No",
    airGapHighSide: "Not capable",
    conversion: "3,000 Ask Sage tokens (completion) = 3 images",
    comment: "Generates 3 images.",
    paidText: "Paid Users Only",
    key: 'dall-e-2',
    value: 'DALL-E (2)',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Azure OpenAI DALL-E-3",
    contextSize: "1,024",
    sensitiveData: "Compliant",
    airGapHighSide: "Not capable",
    conversion: "2,000 Ask Sage tokens (completion) = 1 image",
    comment: "Generates 1 image.",
    paidText: "Paid Users Only",
    key: 'dall-e-3',
    value: 'DALL-E (3)*',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Cohere",
    contextSize: "4,096 (10 pages)",
    sensitiveData: "No, but can be deployed on premise",
    airGapHighSide: "Capable",
    conversion: "1 Ask Sage tokens = 1 tokens",
    comment: "Commercial, can be deployed on premise.",
    paidText: undefined,
    key: 'cohere',
    value: 'Cohere',
    paid: false,
    superadmin_only: false 
  },
  {
    model: "Groq-70B",
    contextSize: "8,192 (20 pages)",
    sensitiveData: "No",
    airGapHighSide: "Possible",
    conversion: "1 Ask Sage tokens = 22 tokens",
    comment: "Best model provided by Groq. 70B.",
    paidText: "Paid Users Only",
    key: 'groq-70b',
    value: 'Groq-70B',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Claude 2",
    contextSize: "100,000 (250 pages)",
    sensitiveData: "No",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 1.81 tokens\n1 Ask Sage tokens (completion) = 0.61 tokens",
    comment: "Largest token window, very capable but not CUI.",
    paidText: "Paid Users Only",
    key: 'claude2',
    value: 'Claude 2',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Claude 3 Opus",
    contextSize: "200,000 (500 pages)",
    sensitiveData: "No",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 1.34 tokens\n1 Ask Sage tokens (completion) = 0.26 tokens",
    comment: "Largest token window, very capable but not CUI. Top-level performance, intelligence, fluency, and understanding.",
    paidText: "Paid Users Only",
    key: 'claude-3-opus',
    value: 'Claude 3 O.',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Claude 3 Sonnet",
    contextSize: "200,000 (500 pages)",
    sensitiveData: "No",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 6.67 tokens\n1 Ask Sage tokens (completion) = 1.34 tokens",
    comment: "Largest token window, very capable but not CUI. Maximum utility at a lower price, dependable, balanced for scaled deployments",
    paidText: "Paid Users Only",
    key: 'claude-3-sonnet',
    value: 'Claude 3 S.',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Claude 3.5 Sonnet",
    contextSize: "200,000 (500 pages)",
    sensitiveData: "No",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 6.67 tokens\n1 Ask Sage tokens (completion) = 1.34 tokens",
    comment: "Latest model. Largest token window, very capable but not CUI. Maximum utility at a lower price, dependable, balanced for scaled deployments",
    paidText: "Paid Users Only",
    key: 'claude-35-sonnet',
    value: 'Claude 3.5 S.',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "LLMA 2",
    contextSize: "8,192 (20 pages)",
    sensitiveData: "Capable",
    airGapHighSide: "Capable",
    conversion: "1 Ask Sage tokens = 0.1 tokens",
    comment: "Sponsored by AWS Gov. Open-source model, can be deployed air-gapped",
    paidText: undefined,
    key: 'llma3',
    value: 'LLMA-3',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "AWS Bedrock Titan",
    contextSize: "8,192 (20 pages)",
    sensitiveData: "Not yet",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 25 tokens\n1 Ask Sage tokens (completion) = 12.5 tokens",
    comment: "Basic AWS model",
    paidText: "Paid Users Only",
    key: 'aws-bedrock-titan',
    value: 'Titan*',
    paid: true,
    superadmin_only: true 
  },
  {
    model: "Google Bison",
    contextSize: "8,192 (20 pages)",
    sensitiveData: "Not yet",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens = 5 tokens",
    comment: "Google PaLM2 - Google Bard's model, average results",
    paidText: "Paid Users Only",
    key: 'google-bison',
    value: 'Google Bison',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Google Gemini Pro",
    contextSize: "32,000 (80 pages)",
    sensitiveData: "Not yet",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens = 40 tokens",
    comment: "Comparable to GPT 3.5 but cheaper and larger token window.",
    paidText: "Paid Users Only",
    key: 'google-gemini-pro',
    value: 'Google Gemini Pro',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "Mistral Large (Azure)",
    contextSize: "32,000 (80 pages)",
    sensitiveData: "Not yet",
    airGapHighSide: "Not capable",
    conversion: "1 Ask Sage tokens (prompt) = 2.5 tokens\n1 Ask Sage tokens (completion) = 0.84 tokens",
    comment: "Comparable to GPT 4 but cheaper and larger token window. Hosted in France but available in US as well.",
    paidText: "Paid Users Only",
    key: 'mistral-large',
    value: 'Mistral Large',
    paid: true,
    superadmin_only: false 
  },
  {
    model: "MPT-7B-Chat",
    contextSize: "4,096 (10 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "Additional cost",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: "Additional cost",
    key: 'mpt-7b-chat',
    value: 'MPT-7B-Chat*',
    paid: true,
    superadmin_only: true 
  },
  {
    model: "Dolly v2",
    contextSize: "2,048 (5 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "Additional cost",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: "Additional cost",
    key: 'dolly',
    value: 'Dolly*',
    paid: true,
    superadmin_only: true 
  },
  {
    model: "Flan T5-XL",
    contextSize: "1,024 (2.5 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "Additional cost",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: "Additional cost",
    key: 'flan-t5',
    value: 'Flan T5-XL*',
    paid: true,
    superadmin_only: true 
  },
  {
    model: "Falcon",
    contextSize: "2,048 (5 pages)",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "Additional cost",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: "Additional cost",
    key: 'falcon',
    value: 'Falcon*',
    paid: true,
    superadmin_only: true 
  },
  {
    model: "Mistral/Mixtral",
    contextSize: "various",
    sensitiveData: "Compliant",
    airGapHighSide: "Capable",
    conversion: "Additional cost",
    comment: "Open-source model, can be deployed air-gapped",
    paidText: "Additional cost",
  }
]